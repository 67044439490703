import React, { useEffect } from "react";
import styled from "styled-components";

const StyledModalContainer = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  z-index: 100;
  .content-container {
    margin-top: 100px;
    background: var(--bg);
    height: ${(props) => props.height || 200}px;
    min-height: min-content;
    width: 400px;
    padding: 20px;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 1000;

    h2,
    p {
      text-align: center;
    }

    .button-container {
      display: flex;
      justify-content: space-between;
      button {
        width: max-content;
      }
    }
  }
`;

export default function Modal(props) {
  const { isModalOpen, setIsModalOpen, children, height } = props;

  if (!isModalOpen) {
    return null;
  }

  return (
    <StyledModalContainer
      onClick={(e) => {
        e.preventDefault();
        setIsModalOpen(false);
      }}
      height={height}
    >
      <div
        className="content-container"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {children}
      </div>
    </StyledModalContainer>
  );
}
