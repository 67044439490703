import React from "react";
import Modal from "./Modal";

export default function ShortcutModal(props) {
  const { isModalOpen, setIsModalOpen, isDemo = false } = props;
  return (
    <Modal
      height={300}
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
    >
      <div>
        <h2>Keyboard Shortcuts</h2>
        <ul>
          <li>
            <strong>Right Arrow </strong>- Go forward 3 seconds
          </li>
          <li>
            <strong>Left Arrow </strong> - Go back 3 seconds
          </li>
          <li>
            <strong>Spacebar</strong> - Toggle Play and Pause of the Video
          </li>
        </ul>
        <h3 style={{ textAlign: "center" }}>Transcript Interactions</h3>
        <ul>
          <li>
            <strong>Click </strong>on a word to get the defintion
          </li>
          {!isDemo && (
            <li>
              <strong>W + Click</strong> on a word or words to save as a
              flashcard
            </li>
          )}
          <li>
            <strong>V + Click</strong> on a verb to get the conjugation if
            possible
          </li>
        </ul>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <button
            onClick={(e) => {
              // * API call to delete
              setIsModalOpen("");
            }}
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
}
